import { parse } from 'date-fns'
import type { BundleSelectionItem, Charge, LineItem, IntervalUnit, Onetime, Subscription, BundleSelection } from '@rechargeapps/storefront-client'
import { getProp } from './props'
import { isBundleParentSku, isBundleChildSku, isSingleFlavorItem } from './bundles'

import type { LineItemBundle, PackSize } from '@/types/recharge'

export const pluralizeUnit = (unit: IntervalUnit) => unit.replace(/s?$/, 's')

const groupBundles = (items: LineItem[]) => {
  const parents = items.filter(item => isBundleParentSku(item.sku))
  const children = items.filter(item => isBundleChildSku(item.sku))
  return parents.reduce<LineItemBundle[]>((acc, parent) => {
    acc.push({
      parent,
      children: children.filter(i => String(getProp(i, '_rc_bundle')) === String(parent.purchase_item_id))
    })
    return acc
  }, [])
}

export const singleSubscriptionItems = (items: LineItem[]) => (
  items.filter(item => item.purchase_item_type === 'subscription')
)

export const separateChargeItems = (items: LineItem[]) => {
  const nonBundleItems = items.filter(item => !(isBundleParentSku(item.sku) || isBundleChildSku(item.sku)))
  const singleFlavorItems = items.filter(item => isSingleFlavorItem(item))

  const subscriptions = singleSubscriptionItems(nonBundleItems)
  const onetimes = [ ...nonBundleItems.filter(item => item.purchase_item_type === 'onetime'), ...singleFlavorItems ]
  const other = nonBundleItems.filter(item =>
    !subscriptions.includes(item) && !onetimes.includes(item)
  )

  return {
    bundles: groupBundles(items),
    subscriptions,
    onetimes,
    other
  }
}

export const sortSelectionItems = (items: BundleSelectionItem[]) => {
  return items.sort((a, b) => {
    const diff = b.quantity - a.quantity
    if (diff === 0) {
      return b.id - a.id
    } else {
      return diff
    }
  })
}

export const getItemsFromCharges = (charges: Charge[]) => {
  const line_items: LineItem[] = []
  for (const charge of charges) {
    line_items.push(...charge.line_items)
  }
  return line_items
}

export const itemTitle = (item: Onetime | Subscription | LineItem) => {
  const nickname = getProp(item, 'nickname')
  const title = typeof nickname === 'string' && nickname.length ? nickname : 'title' in item ? item.title : item.product_title
  const variant = item.variant_title && String(item.variant_title).replace('BlenderBottle', '')

  // <template v-if="isCoffeeProduct"> - Instant Protein Coffee</template>

  return [
    cleanTitle(title),
    COFFEE_ITEM_SKU.test(String(item.sku)) && 'Instant Protein Coffee',
    variant
  ].filter(i => typeof i === 'string' && i.length).join(' - ')
}

export function parseChargeDate (string?: undefined): undefined
export function parseChargeDate (string?: string): Date
export function parseChargeDate (string?: string | undefined) {
  return string ? parse(string, 'yyyy-MM-dd', new Date()) : undefined
}

export const formatChargeDate = (string?: string | null) => string ? formatDateTime(parseChargeDate(string)) : undefined

export const isAddon = (onetime: Subscription | Onetime) => onetime.properties.some(i => i.name === 'addon')

export const getBundlePackSize = (bundleSelectionItems: BundleSelectionItem[]) => {
  return bundleSelectionItems.reduce((acc, current) => acc + current.quantity, 0) as number as PackSize
}

export const getTargetPackSize = (packSize: PackSize): PackSize => (
  {
    8: 16,
    16: 24,
    24: 32,
    32: 32
  }[packSize] as PackSize)

export const resizeBundle = (bundle: BundleSelection, packSize: number, newSize: number) => {
  const newBundle = { ...bundle, items: bundle.items.map(i => ({ ...i })) }

  /* Increment items until limit */
  let itemsCount = packSize
  while (itemsCount < newSize) {
    for (const item of newBundle.items) {
      item.quantity++
      itemsCount++
      if (itemsCount === newSize) {
        break
      }
    }
  }
  return newBundle
}

export const sortSubscriptions = (a: Subscription, b: Subscription) =>
  getBundlePackSize(b.include?.bundle_selections?.items || []) - getBundlePackSize(a.include?.bundle_selections?.items || [])
